import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Faq from '../components/faq'
import Facts from '../components/facts'
import useOptions from '../hooks/use-options'
import countries from '../data/countries'
// import sortAlpha from 'usfl/array/sort-alpha'

export default function PageDistributors ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  const { hero, faq } = page
  const { templateId } = pageContext
  const { distributors: { distributors } } = useOptions()

  const distroMap = distributors
    .reduce((ob, item) => {
      if (!ob[item.country]) {
        ob[item.country] = []
      }
      ob[item.country].push(item)
      return ob
    }, {})

  const items = Object.keys(distroMap)
    // .sort(sortAlpha)
    .map(key => {
      return {
        title: countries[key],
        content: distroMap[key].reduce((str, item) => {
          return str + `
          <p><a class='Button--underline' href='${item.url}' target='_blank' rel='noopener noreferrer'>
            ${item.buyButtonLabel}
          </a></p>`
        }, '')
      }
    })

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article>
        <Hero data={hero} />
        <Facts alignLeft data={{ facts: items }} />
        {!!(faq && faq.faqs) && (
          <section className='u-sectionPadded u-sectionDivider'>
            <Faq
              modifier='Faq--center'
              title={faq.faqTitle}
              faqs={faq.faqs}
            />
          </section>
        )}
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageStockistsQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...HeroFragment
        ...FaqFragment
      }
    }
  }
`
